/* eslint-disable react/prop-types */
import React, { Component, Suspense, lazy } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import PropTypes from 'prop-types';
import { authStatusListener, removeAuthListener } from './actions/authActions';
import userActions from './actions/userActions';
import Header from './components/Header/Header';
import Home from './containers/Home';
import ErrorSlide from './containers/ErrorSlide';
import Login from './containers/Login';
import Logout from './containers/Logout';
import Order from './containers/Order';
import subscriptionActions, { subscriptionListener } from './actions/subscriptionActions';
import ResetPassword from './containers/ResetPassword';
import AddToHomeScreen from './helpers/Message/AddToHomeScreen';
import AddressBookEditItem from './components/AddressBook/AddressBookEditItem';
import Spinner from './helpers/Spinner/Spinner';
import {
  loadTranslationsAsync,
  changeLanguage,
  allowedLanguageAndCountryCodes,
  resolveLanguageAndCountry,
  resolveLanguageAndCountryArray,
} from './services/i18n';
import Shop from './components/Shop/Shop';
import AddressBook from './components/AddressBook/AddressBook';
import AddressBookNew from './components/AddressBook/AddressBookNew';
import Invoices from './components/Invoices/Invoices';
import Profile from './components/Profile/Profile';
import Footer from './components/Footer/Footer';

import SubscriptionUpdated from './components/Subscription/SubscriptionUpdated';
import CheckOut from './components/CheckOut/CheckOut';
import Error from './components/CheckOut/Error';
import Success from './components/CheckOut/Success';
import CancelSubscription from './components/Cancel/CancelSubscription';
import CancelOffers from './components/Cancel/CancelOffers';
import CancelSuccess from './components/Cancel/CancelSuccess';
import PauseSuccess from './components/Cancel/PauseSuccess';
import ReactivationSuccess from './components/Cancel/ReactivationSuccess';

import DeleteMyAccount from './components/Profile/DeleteMyAccount';
import OrderHistory from './components/OrderHistory/OrderHistory';
import KickSmartSupport from './components/OrderHistory/KickSmartSupport';
import OrderDetails from './components/OrderHistory/OrderDetails';
import Contact from './components/Contact/Contact';
import PhotoEditor from './components/PhotoEditor/PhotoEditor';
import Checkout from './containers/Checkout/Checkout';
const MemberGetMember = lazy(() => import('./components/MGM/MemberGetMember'));
const SendSlide = lazy(() => import('./components/SendSlide/SendSlide'));
const SmartSupport = lazy(() => import('./components/SmartSupport/SmartSupport'));
const SendToNewAddress = lazy(() => import('./components/SendToNewAddress/SendToNewAddress'));
const SendToNewUKAddress = lazy(() => import('./components/SendToNewAddress/SendToNewAddress'));
const MyOrders = lazy(() => import('./components/MyOrders/MyOrders'));
const ProductFunnel = lazy(() => import('./containers/Product/ProductFunnel'));

import './klikkie.scss';

// eslint-disable-next-line no-shadow
const PrivateRoute = ({ component: RouteComponent, authenticated, location, ...rest }) => {
  const search = (location && location.search) || '';
  return (
    <Route
      {...rest}
      render={(p) =>
        authenticated ? <RouteComponent {...p} /> : <Redirect to={`/${global.language}/login/${search}`} />
      }
    />
  );
};
class RoutedApp extends Component {
  state = {
    loading: true,
    authenticated: false,
    user: null,
    userId: null,
    errorMessage: null,
    subscription: null,
    pathLanguage: '',
  };

  constructor(props) {
    super(props);
    this.handleAuthResponse = this.handleAuthResponse.bind(this);
    // this.handleSubscriptionChangeResponse = this.handleSubscriptionChangeResponse.bind(this);
  }

  async componentDidMount() {
    const {
      match: {
        params: { pathLanguage },
      },
    } = this.props;
    const resolvedLanguage = resolveLanguageAndCountry(pathLanguage);
    await loadTranslationsAsync(resolvedLanguage, false);
    changeLanguage(resolvedLanguage);
    this.setState({ pathLanguage: resolvedLanguage });
    authStatusListener(this.handleAuthResponse);
  }

  componentWillUnmount() {
    removeAuthListener();
  }

  // eslint-disable-next-line class-methods-use-this
  getLanguagePath() {
    // TODO: externalice languages
    return `:pathLanguage(${allowedLanguageAndCountryCodes.join('|')})`;
  }

  async handleSubscriptionChangeResponse(snapshot) {
    console.log('handleSubscriptionChangeResponse', { snapshot });
    console.log('subscription was updated', snapshot.val());
    console.log('subscription was updated state', this.state);
    // const updatedSubscription = snapshot.val();
    // const currentState = this.state;
    console.log('subscription was updated state', { currentState });
    // this.setState({
    //   ...currentState,
    //   subscription: updatedSubscription,
    // });
  }

  async handleAuthResponse(authResult, callBack) {
    const authUser = authResult?.additionalUserInfo ? authResult.user : authResult;
    const additionalUserInfo = authResult?.additionalUserInfo;

    // Return early if user is not authenticated
    if (!authUser) {
      this.setState(
        {
          user: null,
          authenticated: false,
          loading: false,
        },
        callBack
      );
      return;
    }
    const user = authUser;
    const userId = authUser.uid;

    try {
      // Get the subscription data
      const subscription = await subscriptionActions.getSubscription(user.uid);
      // subscriptionListener(this.handleSubscriptionChangeResponse);
      // Get the user's preferred language
      const language = await userActions.getProfileLanguage(userId);
      const resolvedLanguage = resolveLanguageAndCountry(language);
      // Send to tagManager
      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
          subscription,
          user,
          userId,
          userID: userId, // datalayer is using the wrong variable it seems
          resolvedLanguage,
        },
      });

      if (additionalUserInfo !== undefined) {
        userActions
          .getUser(authUser.uid)
          .then(async (getUser) => {
            const updatesNeeded = {};

            if (!getUser.profile?.email) {
              updatesNeeded.email = authUser.email;
            }

            if (!getUser.profile?.name) {
              updatesNeeded.name = additionalUserInfo?.profile?.given_name || authUser?.displayName || '';
            }

            if (!getUser.profile?.surname) {
              updatesNeeded.surname = additionalUserInfo?.profile?.family_name || authUser?.family_name || '';
            }

            if (!getUser.profile?.countryCode || !getUser.profile?.homeRegion) {
              const getCountryInfo = await userActions.getCountry();
              updatesNeeded.countryCode = getCountryInfo.country || 'EU';
              updatesNeeded.homeRegion = getCountryInfo?.homeRegion || '';
            }

            if (!getUser.profile?.languageCode) {
              const resolvedLanguageArr = resolveLanguageAndCountryArray(language);
              updatesNeeded.languageCode = resolvedLanguageArr?.[0] || 'en';
            }

            if (Object.keys(updatesNeeded).length > 0) {
              await userActions.setUser(updatesNeeded);
            }
          })
          .catch(async (error) => {
            const getCountryInfo = await userActions.getCountry();
            const resolvedLanguageArr = resolveLanguageAndCountryArray(language);

            await userActions.setUser({
              email: authUser.email,
              countryCode: getCountryInfo.country || 'EU',
              homeRegion: getCountryInfo?.homeRegion || '',
              languageCode: resolvedLanguageArr?.[0] || 'en',
              name: additionalUserInfo?.profile?.given_name || authUser?.displayName || '',
              surname: additionalUserInfo?.profile?.family_name || authUser?.family_name || '',
            });
          });
      }

      // Update the component state with the subscription and user data
      this.setState(
        {
          subscription,
          authenticated: true,
          user,
          userId,
          loading: false,
        },
        () => {
          changeLanguage(resolvedLanguage);
          if (callBack) callBack(resolvedLanguage);
        }
      );
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
      if (callBack) callBack(null, error);
    }
  }

  navigateToError(errorMessage) {
    const { history } = this.props;
    this.setState({ loading: false, errorMessage });
    history.push('/error');
  }

  render() {
    const { authenticated, subscription, user, errorMessage, loading, userId } = this.state;
    const { history } = this.props;
    const langPath = this.getLanguagePath();
    if (loading) {
      return <Spinner />;
    }
    return (
      <div className="app-container">
        {!location.pathname.includes('/photo-editor') && (
          <Header
            authenticated={authenticated}
            changeLanguage={(lang) => {
              const { pathname } = history.location;
              const updatedPathname = pathname.replace(/^\/\S{5}\//, `/${lang}/`);
              console.log('updatedPathname', updatedPathname);
              changeLanguage(lang);
              history.replace(updatedPathname);
            }}
          />
        )}
        <AddToHomeScreen authenticated={authenticated} />
        <Switch>
          <PrivateRoute
            path={`/${langPath}/`}
            exact
            authenticated={authenticated}
            component={(p) => <Home userId={userId} handleError={() => this.navigateToError} {...p} />}
          />
          <PrivateRoute
            path={`/${langPath}/orders`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <MyOrders
                  subscription={subscription}
                  user={user}
                  userId={userId}
                  orderId={p.match.params.id}
                  handleError={() => this.navigateToError}
                  {...p}
                />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/profile`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <Profile user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/orderHistory/KickSmartSupport/:orderId`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <KickSmartSupport
                  user={user}
                  userId={userId}
                  orderId={p.match.params.orderId}
                  handleError={() => this.navigateToError}
                  {...p}
                />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/orderDetails/:orderId`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <OrderDetails
                  user={user}
                  userId={userId}
                  orderId={p.match.params.orderId}
                  handleError={() => this.navigateToError}
                  {...p}
                />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/orderHistory`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <OrderHistory user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/checkout/:id`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <CheckOut
                  user={user}
                  userId={userId}
                  handleError={() => this.navigateToError}
                  history={history}
                  {...p}
                />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/failed/:id`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <Error user={user} userId={userId} handleError={() => this.navigateToError} history={history} {...p} />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/success/:id`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <Success
                  user={user}
                  userId={userId}
                  handleError={() => this.navigateToError}
                  history={history}
                  {...p}
                />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/subscription`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <SubscriptionUpdated user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />

          <PrivateRoute
            path={`/${langPath}/cancel`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <CancelSubscription user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />

          <PrivateRoute
            path={`/${langPath}/cancel-offers`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <CancelOffers
                  user={user}
                  userId={userId}
                  subscription={subscription}
                  handleError={() => this.navigateToError}
                  {...p}
                />
              </Suspense>
            )}
          />

          <PrivateRoute
            path={`/${langPath}/cancel-success`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <CancelSuccess user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />

          <PrivateRoute
            path={`/${langPath}/membership-cancelled`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <CancelSuccess user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/delete`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <DeleteMyAccount user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/membership-paused`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <PauseSuccess user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />
          <PrivateRoute
            path={`/${langPath}/membership-reactivated`}
            lang
            authenticated={authenticated}
            history={history}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <ReactivationSuccess user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />

          <PrivateRoute
            path={`/${langPath}/pause/success`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <PauseSuccess user={user} userId={userId} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />

          <PrivateRoute
            path={`/${langPath}/order/:id`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Order
                subscription={subscription}
                user={user}
                userId={userId}
                orderId={p.match.params.id}
                handleError={() => this.navigateToError}
                {...p}
              />
            )}
          />
          <PrivateRoute
            path={`/${langPath}/order/:id/select-set`}
            lang
            authenticated={authenticated}
            component={(p) => (
              <Order
                subscription={subscription}
                user={user}
                userId={userId}
                orderId={p.match.params.id}
                handleError={() => this.navigateToError}
                {...p}
              />
            )}
          />
          <PrivateRoute
            path={`/${langPath}/send/:id`}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <SendSlide
                  subscription={subscription}
                  orderId={p.match.params.id}
                  user={user}
                  userId={userId}
                  handleError={() => this.navigateToError}
                  history={history}
                  {...p}
                />
              </Suspense>
            )}
            authenticated={authenticated}
            user={user}
          />
          <PrivateRoute
            path={`/${langPath}/SmartSupport/:id`}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <SmartSupport
                  subscription={subscription}
                  orderId={p.match.params.id}
                  user={user}
                  userId={userId}
                  handleError={() => this.navigateToError}
                  history={history}
                  {...p}
                />
              </Suspense>
            )}
            authenticated={authenticated}
            user={user}
          />
          <PrivateRoute
            path={`/${langPath}/address/:id`}
            component={(p) =>
              global.language === 'en-EU' ? (
                <Suspense fallback={<Spinner />}>
                  <SendToNewUKAddress
                    subscription={subscription}
                    orderId={p.match.params.id}
                    user={user}
                    handleError={() => this.navigateToError}
                    {...p}
                  />
                </Suspense>
              ) : (
                <Suspense fallback={<Spinner />}>
                  <SendToNewAddress
                    subscription={subscription}
                    orderId={p.match.params.id}
                    user={user}
                    handleError={() => this.navigateToError}
                    {...p}
                  />
                </Suspense>
              )
            }
            authenticated={authenticated}
            user={user}
          />
          <PrivateRoute
            exact
            path={`/${langPath}/addressbook/edit/:id`}
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <AddressBookEditItem addressId={p.match.params.id} userId={userId} />
              </Suspense>
            )}
          />
          <PrivateRoute
            exact
            path={`/${langPath}/addressbook/:id`}
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <AddressBook orderId={p.match.params.id} user={user} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />
          <PrivateRoute
            exact
            path={`/${langPath}/addressbook/`}
            authenticated={authenticated}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <AddressBookNew userId={userId} user={user} handleError={() => this.navigateToError} {...p} />
              </Suspense>
            )}
          />
          <Route
            path={`/${langPath}/error`}
            component={(p) => <ErrorSlide message={errorMessage} {...p} />}
            authenticated={authenticated}
          />
          <PrivateRoute
            path={`/${langPath}/invite`}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <MemberGetMember userId={userId} {...p} />
              </Suspense>
            )}
            authenticated={authenticated}
          />
          <PrivateRoute
            path={`/${langPath}/shop`}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <div className="page-container">
                  <section className="content">
                    <Shop userId={userId} {...p} />
                  </section>
                  <Footer className="footer" />
                </div>
              </Suspense>
            )}
            authenticated={authenticated}
          />
          <PrivateRoute
            path={`/${langPath}/invoices`}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <Invoices userId={userId} {...p} />
              </Suspense>
            )}
            authenticated={authenticated}
          />
          <PrivateRoute
            path={`/${langPath}/product/:id/:rest([^]*)`}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <div className="page-container">
                  <section className="content">
                    <Checkout packageId={p.match.params.id} userId={userId} {...p} />
                  </section>
                  <Footer className="footer" />
                </div>
              </Suspense>
            )}
            authenticated={authenticated}
            user={user}
          />
          <PrivateRoute
            path={`/${langPath}/product/:id`}
            component={(p) => (
              <Suspense fallback={<Spinner />}>
                <ProductFunnel
                  packageId={p.match.params.id}
                  userId={userId}
                  handleError={() => this.navigateToError}
                  {...p}
                />
              </Suspense>
            )}
            authenticated={authenticated}
            user={user}
          />
          <Route
            path={`/${langPath}/login`}
            render={(props) => <Login setCurrentUser={this.handleAuthResponse} {...props} />}
          />
          <Route path={`/${langPath}/logout`} component={Logout} />
          <Route path={`/${langPath}/reset`} component={ResetPassword} />
          <Route path={`/${langPath}/contact`} component={Contact} />
          <Route path={`/_/${langPath}/photo-editor`} component={PhotoEditor} />
        </Switch>
      </div>
    );
  }
}
RoutedApp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};
export default withRouter(RoutedApp);
