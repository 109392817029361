import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { fabric } from 'fabric';
import { Template, TemplateObject } from './BitmapEditor.types';
import { drawObject } from './objects';
import { drawImageElement } from './objects/BitmapEditor.ImageObject';
import { ILimitedIText, LimitedIText } from './objects/BitmapEditor.TextObject';
import Cropper from './Cropper';
import { Object } from 'fabric/fabric-impl';

const BitmapEditor = forwardRef(
  (
    {
      template,
      setScale,
      setIsLoading,
      setActiveObject,
      productKey,
      sideContainer,
    }: {
      template: Template;
      setScale: any;
      setIsLoading: any;
      setActiveObject: any;
      productKey: string;
      sideContainer: string;
    },
    ref
  ) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const fabricCanvasRef = useRef<fabric.Canvas | null>(null);
    const [scaleFactor, setScaleFactor] = useState(1);
    const [src, setSrc] = useState<string>('');
    const [cropModalVisible, setCropModalVisible] = useState(false);
    const [selectedTemplateObject, setSelectedTemplateObject] = useState<TemplateObject | null>(null);
    const [selectedObject, setSelectedObject] = useState<Object | null>(null);

    useEffect(() => {
      setActiveObject(selectedObject);
    }, [selectedObject]);

    const selectImageHandler = (arg1: any, object: TemplateObject) => {
      setSrc(arg1);
      setCropModalVisible(true);
      setSelectedTemplateObject(object);
      const fabricCanvas = fabricCanvasRef.current;
      if (!fabricCanvas) return;
      // @ts-expect-error
      const imageObject = fabricCanvas.getObjects().find((obj) => obj?.id === object.id);
      if (!imageObject) return;
      setSelectedObject(imageObject);
    };

    const handleCropComplete = (croppedImage: string) => {
      if (selectedTemplateObject && fabricCanvasRef.current) {
        const selectedObjectToPass = { ...selectedTemplateObject, src: croppedImage };
        drawImageElement(fabricCanvasRef.current, selectedObjectToPass, (arg1: any) =>
          selectImageHandler(arg1, selectedObjectToPass)
        );
        setSelectedTemplateObject(null);
      }
    };

    const limitSize = (size: any, maximumPixels: any) => {
      const { width, height } = size;

      const requiredPixels = width * height;
      if (requiredPixels <= maximumPixels) return { width, height };

      const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);
      return {
        width: Math.floor(width * scalar),
        height: Math.floor(height * scalar),
      };
    };

    useEffect(() => {
      if (!template) return;
      if (!canvasRef.current) return;

      const devicePixelRatio = window.devicePixelRatio || 1;
      const { width, height } = limitSize({ width: template.width, height: template.height }, 16777216);

      const fabricCanvas = new fabric.Canvas(canvasRef.current, {
        backgroundColor: template.backgroundColor,
        preserveObjectStacking: true,
        selection: false,
      });
      fabricCanvasRef.current = fabricCanvas;
      const setSelectedObjectFromEvent = (e: fabric.IEvent<MouseEvent>) => {
        if (!e.selected) return;
        setSelectedObject(e.selected[0]);
      };

      fabricCanvas.on('selection:created', setSelectedObjectFromEvent);
      fabricCanvas.on('selection:updated', setSelectedObjectFromEvent);
      fabricCanvas.on('selection:cleared', () => setSelectedObject(null));

      // Add listener for clicks outside of the canvas to deselect objects
      const canvasEl = document.querySelector('.canvas-wrapper');
      document.addEventListener('click', (e: MouseEvent) => {
        if (
          canvasEl &&
          e.target &&
          !canvasEl.contains(e.target as HTMLElement) &&
          !(e.target as HTMLElement).closest('.font-list-wrapper')
        ) {
          fabricCanvas.discardActiveObject();
          fabricCanvas.renderAll();
        }
      });

      const resizeHandler = () => {
        scaleCanvasToWindow(template.width, template.height);
      };
      const newScaleFactor = scaleCanvasToWindow(template.width, template.height);
      window.addEventListener('resize', resizeHandler);
      setIsLoading(false);

      if (sideContainer === '.cardBack' && template.backSide) {
        template.backSide.objects.forEach(async (object: TemplateObject) => {
          await drawObject(fabricCanvas, object, (arg1: any) => selectImageHandler(arg1, object), sideContainer);
        });
      } else {
        template.objects.forEach(async (object: TemplateObject) => {
          await drawObject(fabricCanvas, object, (arg1: any) => selectImageHandler(arg1, object), sideContainer);
        });
      }

      return () => {
        window.removeEventListener('resize', resizeHandler);
        fabricCanvas.dispose();
      };
    }, [template]);

    const scaleCanvasToWindow = (width: number, height: number) => {
      const fabricCanvas = fabricCanvasRef.current;
      if (!fabricCanvas) return;

      const uiElementsVerticalHeight = 160;
      const paddingPercentage = 0.2;
      const canvasPercentage = 1 - paddingPercentage;
      const sectionWidth = window.innerWidth * canvasPercentage;
      const sectionHeight = (window.innerHeight - uiElementsVerticalHeight) * canvasPercentage;
      const newScaleFactor = Math.min(sectionWidth / width, sectionHeight / height);

      const limitedSize = limitSize(
        { width: Math.round(width * newScaleFactor), height: Math.round(height * newScaleFactor) },
        16777216
      );

      setScaleFactor(newScaleFactor);
      setScale(newScaleFactor);
      fabricCanvas.setDimensions(limitedSize);
      fabricCanvas.setZoom(newScaleFactor);

      fabricCanvas.requestRenderAll();

      return newScaleFactor;
    };

    const saveCanvas = (imageQuality = 0.9) => {
      const fabricCanvas = fabricCanvasRef.current;
      if (!fabricCanvas) return null;

      fabricCanvas.getObjects().forEach((obj) => {
        if (obj instanceof LimitedIText) {
          const textObj = obj as ILimitedIText;
          textObj.fill = 'black';
          textObj.placeHolderFill = 'black';
        }
      });

      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();

      const multiplier = 1 / scaleFactor;
      const canvasWidth = fabricCanvas.width ?? 0;
      const canvasHeight = fabricCanvas.height ?? 0;

      const { width, height } = limitSize({ width: template.width, height: template.height }, 16777216);

      const adjustedMultiplier = Math.min(multiplier, width / canvasWidth, height / canvasHeight);

      // Return the data URL
      return fabricCanvas.toDataURL({
        format: 'jpeg',
        quality: imageQuality,
        multiplier: adjustedMultiplier,
      });
    };

    useImperativeHandle(ref, () => ({
      saveCanvas,
    }));

    return (
      <>
        <canvas id="canvas" ref={canvasRef} />
        {selectedTemplateObject && (
          <Cropper
            cropModalVisible={cropModalVisible}
            setCropModalVisible={setCropModalVisible}
            src={src}
            width={selectedTemplateObject?.width ?? 50}
            height={selectedTemplateObject?.height ?? 50}
            onCropComplete={handleCropComplete}
          />
        )}
      </>
    );
  }
);

export default BitmapEditor;
