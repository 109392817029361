import React, { useEffect, useRef, useState } from 'react';
import '../PhotoEditor.scss';
import addImage from '../images/addimage.png';
import editText from '../images/editText.png';
import Collage from '../images/collage.png';
import FrameColor from '../images/frameColor.png';
import productActions from '../../../actions/productsActions';
import Modal from '../../Modal/Modal';
import { trackingClick } from '../../../actions/trackingActions';
import { useTranslation } from 'react-i18next';
import FrameColorMenu from './FrameColorMenu';
import { CollageMenu } from './CollageMenu';

export enum MenuTypes {
  DEFAULT = 'default',
  ADD_IMAGE = 'Add Image',
  EDIT_TEXT = 'Edit Text',
  COLLAGE = 'Collage',
  FRAME_COLOR = 'Frame Color',
}

export enum FrameColors {
  BLACK = 'black',
  NATURAL = 'natural',
  BROWN = 'brown',
  WHITE = 'white',
  LIGHT_GREY = 'light grey',
}

export default function EditorMenu(props: any) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [productColors, setProductColor] = useState<FrameColors[]>([]);
  const { t } = useTranslation();
  const tempCollage = useRef('Frame-Classic-2x2-2T');

  useEffect(() => {
    const getProductInfo = async () => {
      const params = new URLSearchParams(window.location.search);
      if (params.has('sku')) {
        const productInfo = await productActions.getProduct(params.get('sku'));
        if (productInfo.type !== 'wall' && productInfo.type !== 'card') {
          window.location.href = 'https://www.klikkie.com/products/walldecor';
        }

        if(productInfo.type === 'wall') {
          setProductColor(productInfo.metadata.attributes.frameColor);
        }
      }
    };
    getProductInfo();
  }, [history]);

  useEffect(() => {
    if (props.selectedCollage) tempCollage.current = props.selectedCollage;
  }, [props.selectedCollage]);

  const handleCollageClick = (collageName: string) => {
    trackingClick('bitmapEditor', `select_${collageName}_collage`);
    tempCollage.current = collageName;
    setShowModal(true);
  };

  const confirmChange = () => {
    trackingClick('bitmapEditor', `confirm_change_to_${props.selectedCollage}_collage`);
    props.handleTemplateChangeByName(tempCollage.current);
    props.setSelectedCollage(tempCollage.current);
    setShowModal(false);
    props.setSelectedMenu('default');
    const buttons = document.querySelectorAll('.select-image-btn');
    buttons.forEach((button) => button.remove());
  };

  const cancelChange = () => {
    trackingClick('bitmapEditor', `cancel_change_to_${props.selectedCollage}_collage`);
    setShowModal(false);
  };

  const defaultMenu = (
    <>
      <div
        className="pointer"
        onClick={() => {
          trackingClick('bitmapEditor', 'click_collage_btn');
          props.setSelectedMenu('Collage');
        }}
      >
        <img className="menuItemImg" src={Collage} alt="Collage" />
        <div className="menuItemText">{t('BitmapEditor.menu.collage')}</div>
      </div>
      {props.productType === 'wall' && (
        <div
          className="pointer"
          onClick={() => {
            trackingClick('bitmapEditor', 'click_select_frame_color_btn');
            props.setSelectedMenu('Frame Color');
          }}
        >
          <img className="menuItemImg" src={FrameColor} alt="Frame Color" />
          <div className="menuItemText">{t('BitmapEditor.menu.frameColor')}</div>
        </div>
      )}
    </>
  );

  return (
    <footer className={props.selectedMenu}>
      {props.selectedMenu !== 'default' && (
        <div className="footerTitleWrapper">
          <div className="footerTitle">
            {props.selectedMenu === 'Add Image' && <img className="titleImage" src={addImage} />}
            {props.selectedMenu === 'Edit Text' && <div>{t('BitmapEditor.menu.editText')}</div>}
            {props.selectedMenu === 'Frame Color' && (
              <>
                <img className="titleImage" src={FrameColor} />
                <div>{t('BitmapEditor.menu.frameColor')}</div>
              </>
            )}
            {props.selectedMenu === 'Collage' && (
              <>
                <img className="titleImage" src={Collage} />
                <div>{t('BitmapEditor.menu.collage')}</div>
              </>
            )}
          </div>
          <div
            className="pointer"
            onClick={() => {
              trackingClick('bitmapEditor', 'close_collage_modal');
              props.setSelectedMenu('default');
            }}
          >
            <p className="close">&#x2715;</p>
          </div>
        </div>
      )}

      <div className="footerContent">
        {props.selectedMenu === MenuTypes.DEFAULT && defaultMenu}
        {props.selectedMenu === MenuTypes.COLLAGE && (
          <CollageMenu
            productType={props.productType}
            handleCollageClick={handleCollageClick}
            selectedCollage={props.selectedCollage}
          />
        )}
        {props.selectedMenu === MenuTypes.FRAME_COLOR && (
          <FrameColorMenu
            productColors={productColors}
            selectedFrame={props.selectedFrame}
            setSelectedFrame={props.setSelectedFrame}
            setSelectedMenu={props.setSelectedMenu}
          />
        )}
      </div>

      <Modal
        showModal={showModal}
        title={t('BitmapEditor.menuModal.title')}
        message={t('BitmapEditor.menuModal.message')}
        confirmText={t('BitmapEditor.menuModal.confirm')}
        cancelText={t('BitmapEditor.menuModal.cancel')}
        onConfirm={confirmChange}
        onCancel={cancelChange}
        showLoadingOnSave={false}
      />
    </footer>
  );
}
