import { v4 as uuidv4 } from 'uuid';
import { apiBaseUrl } from '../config';
import { getUserBearer, getUserId } from './authActions';
import { storage } from '../services/firebase';

export default {
  async saveCanvas(frameType, dataBlob) {
    try {
      let token = null;
      try {
        token = await getUserBearer();
      } catch (e) {
        console.warn('User is not logged in, proceeding without token');
      }

      const newUploadFileUrl = await this.uploadBitmapDesign(dataBlob, frameType);

      const requestBody = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          frameType: frameType,
          image_path: newUploadFileUrl,
        }),
      };

      if (token) {
        requestBody.headers.Authorization = `Bearer ${token}`;
      }

      const response = await fetch(`${apiBaseUrl}/photoEditor`, requestBody);
      if (!response.ok) {
        throw new Error('Error uploading file');
      }

      return response.json();
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  },
  async uploadBitmapDesign(fileBlob, frameType) {
    try {
      const ext = frameType === 'postcard' ? 'pdf' : 'jpg';
      const storageRef = storage.ref();
      const filename = `photo-editor/bitmap-designs-${uuidv4()}-${Date.now()}.${ext}`;
      const fileRef = storageRef.child(filename);
      const metadata = {
        contentType: ext === 'pdf' ? 'application/pdf' : 'image/jpeg',
      };
      await fileRef.put(fileBlob, metadata);
      return filename;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  },
};
