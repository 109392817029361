import './klikkie.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Integration } from '@sentry/types/types/integration.js';
import serviceWorker from './registerServiceWorker';
import App from './App';

const integrations: Integration[] = [new Sentry.BrowserTracing()];
if (process.env.REACT_APP_ENV === 'production') {
  integrations.push(
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    })
  );
}

// Sentry.init({
//   dsn: 'https://38a891656b094b22b748db3d527ce9f3@o262013.ingest.sentry.io/4504655161982976',
//   release: `klikkie-web-app@${process.env.REACT_APP_VERSION}`,
//   integrations,
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

// const root = createRoot(document.getElementById('root'));
// root.render(<App />);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker();
