import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { isIOS, isAndroid } from 'react-device-detect';
import { Font, fontsList } from '../../BitmapEditor/fonts';
import { ILimitedIText } from '../../BitmapEditor/objects/BitmapEditor.TextObject';

export const FontMenu = ({ textObject }: { textObject: ILimitedIText }) => {
  const [selectedFont, setSelectedFont] = useState<Font['name']>(textObject.fontFamily || '');
  const fontListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!textObject.fontFamily) return;
    setSelectedFont(textObject.fontFamily);
  }, [textObject.fontFamily]);

  useEffect(() => {
    const fontListWrapper = fontListRef.current;

    const fixPosition = () => {
      if (window.visualViewport && fontListWrapper) {
        const vv = window.visualViewport;
        let scrollY = window.scrollY || window.pageYOffset;
        console.log('scrollY:', scrollY);
        scrollY = scrollY < 370 ? scrollY : 370;
        fontListWrapper.style.top = `${vv.height + scrollY}px`;
      }
    };

    if (window.visualViewport) {
      const vv = window.visualViewport;
      vv.addEventListener('resize', fixPosition);
      window.addEventListener('scroll', fixPosition, { passive: true });
      fixPosition();

      return () => {
        vv.removeEventListener('resize', fixPosition);
        window.removeEventListener('scroll', fixPosition);
      };
    }
  }, []);

  const handleFontClick = (fontName: Font['name']) => {
    setSelectedFont(fontName);
    textObject.changeFont(fontName);
  };

  const deviceClass = classNames('font-list-wrapper', {
    ios: isIOS,
    android: isAndroid,
  });

  return (
    <div className={deviceClass} ref={fontListRef}>
      {fontsList.map((font) => (
        <div
          onClick={() => handleFontClick(font.name)}
          className="font-list-item-wrapper"
          key={font.name}
        >
          <div className={`font-list-item ${font.name === selectedFont ? 'active' : ''}`} style={{ fontFamily: font.name, fontSize: 12 * font.resize }}>
            {font.name}
          </div>
        </div>
      ))}
    </div>
  );
};