import React from 'react';
import { useTranslation } from 'react-i18next';
import { IProductPackageExtended } from '../../Shop/types';
import { formatPrice } from '../../../utils/CurrencyUtils';
import CheckmarkSelected from '../../../img/icons/CheckmarkSelected.png';
import CheckmarkUnselected from '../../../img/icons/CheckmarkUnselected.png';

interface Props {
  selectedProduct: string;
  productCategory: string;
  variantProducts: any[];
  selectHandler: (productSku: string) => void;
}

const ProductDetailVariantsView = (props: Props) => {
  const { productCategory, variantProducts, selectedProduct, selectHandler } = props;
  const { t } = useTranslation();
  if (variantProducts.length === 0) return null;

  const renderVariantPriceInfo = (productVariant: IProductPackageExtended) => {
    const {
      key: productSku,
      price: productPrice,
      currency: productCurrency,
      currency,
    } = productVariant;

    const formattedPrice = productCategory === 'wall' 
      ? formatPrice(productPrice, currency, 'currency', 1500)
      : formatPrice(productPrice, currency, 'currency');
    const formattedPreDiscountPrice = formatPrice(productPrice, productCurrency);

    return (
      <div>
        <p className={`price ${selectedProduct === productVariant.key ? 'selected' : 'unselected'}`}>
          { productCategory === 'wall' && <span className='preDiscount'>{formattedPreDiscountPrice}</span>}{formattedPrice}
        </p>
      </div>
    );
  };

  const renderVariantItem = (productVariant: any) => {
    return (
      <div
        className={`variant-item ${selectedProduct === productVariant.key ? 'selected' : 'unselected'}`}
        onClick={() => selectHandler(productVariant.key)}
        key={productVariant.key}
      >
        <div>
          <img src={selectedProduct === productVariant.key ? CheckmarkSelected : CheckmarkUnselected} />
        </div>
        <p className={`name ${selectedProduct === productVariant.key ? 'selected' : 'unselected'}`}>
          {t(`products:${productVariant.key}.variant.title`)}
        </p>
        {renderVariantPriceInfo(productVariant)}
      </div>
    );
  };

  return (
    <div className="formats">
      <div className="circle" />
      <div className="variants-list">
        {variantProducts.map((productItem) => {
          return renderVariantItem(productItem);
        })}
      </div>
    </div>
  );
};

ProductDetailVariantsView.defaultProps = {
  themedStyle: {},
  style: {},
};

export default ProductDetailVariantsView;
