/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { allowedLanguageAndCountryCodes, resolveLanguageAndCountry } from './services/i18n';
import ImageProvider from './components/Image/ImageProvider';
import RoutedApp from './RoutedApp';
import Matomo from './services/Matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const createHistory = require('history').createBrowserHistory;

const langPath = `:pathLanguage(${allowedLanguageAndCountryCodes.join('|')})`;

const history = createHistory();

TagManager.initialize({ gtmId: 'GTM-N745VV5' });

const App = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      // Send a virtual pageview event to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pageURL: window.location.href,
          pageTitle: document.title,
          pagePath: location.pathname,
        },
      });

      // Track page view with Matomo
      trackPageView();
    });

    return () => {
      unlisten();
    };
  }, [trackPageView]);

  useEffect(() => {
    // if not localhost
    // if (window.location.hostname === 'localhost') return console.log('Matomo not loaded on localhost');

    _paq.push(['setCookieDomain', '*.klikkie.com']);
    _paq.push(['setDomains', ['*.klikkie.com', '*.www.klikkie.com']]);
    _paq.push(['trackPageView']);
  }, []);

  return (
    <Matomo>
      <ImageProvider>
        <Router history={history}>
          <Switch>
            <Route path={`/_/${langPath}`}>
              <RoutedApp />
            </Route>
            <Route path={`/${langPath}`}>
              <RoutedApp />
            </Route>
            <Route
              path="*"
              component={() => {
                const { pathname, search } = window.location;

                if (
                  pathname.startsWith('/_') &&
                  !new RegExp(`/_/${allowedLanguageAndCountryCodes.join('|')}`).test(pathname)
                ) {
                  const resolvedLanguage = resolveLanguageAndCountry(global.language);

                  const newPath = pathname.replace(/^\/_\/?/, '');
                  return <Redirect to={`/_/${resolvedLanguage}/${newPath}${search}`} />;
                }

                const split = pathname.includes('/') ? pathname.split('/') : pathname;
                const path = split[1];
                const resolvedLanguage = resolveLanguageAndCountry(global.language);
                return <Redirect to={`/${resolvedLanguage}/${path}${search}`} />;
              }}
            />
          </Switch>
        </Router>
      </ImageProvider>
    </Matomo>
  );
};

export default App;
