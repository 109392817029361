import { fabric } from 'fabric';
import { TemplateObject } from '../BitmapEditor.types';
import { Canvas, ITextOptions } from 'fabric/fabric-impl';
import { fontsList } from '../fonts';

type ILimitedITextOptions = ITextOptions & {
  id?: string;
  placeHolderText?: string;
  placeHolderFill?: string;
  maxLines?: number;
  maxWidth?: number;
};

export type ILimitedIText = fabric.IText & {
  id: string;
  placeHolderText: string;
  placeHolderFill: string;
  isPlaceHolder: boolean;
  _textBeforeEdit: string;
  hiddenTextarea: HTMLTextAreaElement;
  maxLines: number;
  maxWidth: number;
  setPrintMode: (isPrintMode: boolean) => void;
  changeFont: (fontFamily: string, init?: boolean) => void;
};

export const LimitedIText = fabric.util.createClass(fabric.IText, {
  initialize: function (text: string, options: ILimitedITextOptions) {
    options || (options = {});
    this.callSuper('initialize', text, options);
    this.placeHolderText = options.placeHolderText || 'Type here...';
    this.placeHolderFill = options.placeHolderFill || 'gray';
    this.originalFill = options.fill || 'black';
    this.isPlaceHolder = true;
    this.hoverRect = null;
    this.id = options.id;
    this.originalFontSize = options.fontSize;
    this.changeFont(options.fontFamily, true);

    if (!text) {
      this.text = this.placeHolderText;
      this.set('fill', this.placeHolderFill);
      this.set('width', options.maxWidth || 150);
    } else {
      this.isPlaceHolder = false;
    }

    this.on('editing:entered', () => {
      this._removePlaceHolder();
      this.canvas.remove(this.hoverRect);
      this.hoverRect = null;
      this.canvas.renderAll();
    });

    this.on('editing:exited', () => {
      this._addPlaceHolder();
      this.isEditing = false;
      this.set('selected', false);
      this.canvas.renderAll();
      console.log('exit editing');
    });

    this.on('selected', () => {
      this.enterEditing();
      if (this.hiddenTextarea) {
        this.hiddenTextarea.focus();
        this.hiddenTextarea.select();
      }
    });

    this.on('mouseover', () => {
      if (this.isEditing) return;
      const scaledPadding = this.padding / this.canvas.getZoom();

      // Calculate the total height of the text
      const totalHeight = this.height;

      this.hoverRect = new fabric.Rect({
        originX: 'center',
        originY: 'top',
        left: this.left,
        top: (this.top || 0) - scaledPadding,
        width: this.width + 2 * scaledPadding,
        height: totalHeight + 2 * scaledPadding,
        stroke: 'gray',
        strokeWidth: 2 / this.canvas.getZoom(),
        fill: 'rgba(0,0,0,0)',
        selectable: false,
        evented: false,
      });

      this.canvas.add(this.hoverRect);
      this.canvas.renderAll();
    });

    this.on('mouseout', () => {
      if (this.hoverRect) {
        this.canvas.remove(this.hoverRect);
        this.hoverRect = null;
        this.canvas.renderAll();
      }
    });
  },

  onInput: function (e: any) {
    const oldText = this.text;

    this._removePlaceHolder();

    // Handle different input types appropriately
    if (e.inputType === 'insertText' || e.inputType === 'insertFromPaste') {
      this.text = oldText + (e.data || '');
    } else if (e.inputType === 'deleteContentBackward' || e.inputType === 'deleteContentForward') {
      this.text = oldText.slice(0, -1);
    } else {
      // Handle other input types, if any
      return;
    }

    // Prevent breaking into new lines if maxLines is reached
    if (this._textLines.length > this.maxLines) {
      console.log('Exceeding maxLines');
      this.text = oldText;
      this.hiddenTextarea.value = oldText;
      return;
    }

    // Prevent the width from exceeding maxWidth
    if (this.getBoundingRect().width > this.maxWidth) {
      if (e.inputType === 'deleteContentBackward' || e.inputType === 'deleteContentForward') {
        this.text = oldText.slice(0, -1);
      } else {
        this.text = oldText;
        this.hiddenTextarea.value = oldText;
      }
    }

    // Restore placeholder if the text is empty
    if (!this.text) {
      console.log('Restoring placeholder');
      this.text = this.placeHolderText;
      this.isPlaceHolder = true;
    }

    this.hiddenTextarea.focus();
    this.callSuper('onInput', e);
  },

  _removePlaceHolder: function () {
    if (this.isPlaceHolder) {
      this.set('text', '');
      this.set('fill', this.originalFill);
      this.isPlaceHolder = false;
    }
  },

  _addPlaceHolder: function () {
    if (!this.text) {
      this.set('text', this.placeHolderText);
      this.set('fill', this.placeHolderFill);
      this.isPlaceHolder = true;
    }
  },

  setText: function (text: string) {
    this.callSuper('setText', text);
    if (!text) {
      this._addPlaceHolder();
    } else {
      this._removePlaceHolder();
    }
  },

  setPrintMode: function (isPrintMode: boolean) {
    if (isPrintMode) {
      this._removePlaceHolder();
    } else {
      this._addPlaceHolder();
    }
    this.canvas.renderAll();
  },

  changeFont: function (fontFamily: string, init = false) {
    const resize = fontsList.find((font) => font.name === fontFamily)?.resize;
    this.set('fontFamily', fontFamily);
    this.set('fontSize', this.originalFontSize * (resize || 1));
    const cursorPosition = this.selectionStart;

    if (!init) {
      if (this.hiddenTextarea) {
        // Focus the hidden textarea
        this.hiddenTextarea.focus();
        // Set cursor position in the hidden textarea
        this.hiddenTextarea.setSelectionRange(cursorPosition, cursorPosition);
      }

      if (cursorPosition !== undefined) {
        this.setSelectionStart(cursorPosition);
        this.setSelectionEnd(cursorPosition);
      }
    }

    this.canvas && this.canvas.renderAll();
  },

  onKeyDown: function (e: KeyboardEvent) {
    this._removePlaceHolder();
    if (this._textLines.length > this.maxLines) {
      e.preventDefault();
    }
    this.callSuper('onKeyDown', e);
  },

  onBlur: function () {
    this._addPlaceHolder();
    this.callSuper('onBlur');
  },

  _render: function (ctx: CanvasRenderingContext2D) {
    this.callSuper('_render', ctx);
    if (this.isPlaceHolder && this.text === this.placeHolderText) {
      this.set('fill', this.placeHolderFill);
    } else {
      this.set('fill', this.originalFill);
    }
  },

});

export const drawText = (canvas: Canvas, object: TemplateObject): ILimitedIText => {
  const canvasElement = document.getElementsByClassName('upper-canvas')[0];

  let maxWidth = object.width;
  if (canvasElement && object.width) {
    const canvasRect = canvasElement.getBoundingClientRect();
    const ratio = canvasRect.width / canvasRect.height;

    maxWidth = Math.min(object.width * ratio, canvasRect.width - 60);
  }

  const text: ILimitedIText = new LimitedIText('', {
    id: object.id,
    maxLines: 1,
    maxWidth: maxWidth,
    charSpacing: 100,
    originX: 'center',
    originY: 'top',
    left: object.x,
    top: object.y,
    editingBorderColor: '#18b2bb',
    borderScaleFactor: 2,
    fontFamily: object.fontFamily,
    fontSize: object.font ? parseInt(`${object.font}`) : 20,
    fill: object.fillStyle || 'black',
    placeHolderText: object.value || 'Type here...',
    placeHolderFill: 'gray',
    lineHeight: object.lineHeight ?? 1.2,
    textAlign: object.textAlign ?? 'left',
    selectable: true,
    hasControls: false,
    cursorWidth: 1,
    hoverCursor: 'text',
    padding: 9,
    objectCaching: false,
  });

  canvas.add(text);

  return text;
};