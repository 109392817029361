import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import LocizeBackend from 'i18next-locize-backend';

import { reactI18nextModule } from 'react-i18next';

import { formatCurrency } from '../utils/CurrencyUtils';
import { getLocaleFormattedDate, getLocaleLongMonthFromTimestamp } from '../utils/DateUtils';
import { removePrefix } from '../utils/StringUtils';

const NUMERAL_PREFIX = 'numerall___';

const localStorageBackendOptions = {
  // prefix for stored languages
  prefix: 'i18next_res_',

  // expiration
  // 30 minutes
  expirationTime: process.env.REACT_APP_LOCIZE_VERSION === 'development' ? 30 * 1000 : 30 * 60 * 1000,

  // language versions
  versions: 'latest',

  // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
  store: window.localStorage,
};

/**
 * Locize options and constants
 *
 * Options regarding languages and namespaces should be set in i18next options
 */
const locizeBackendOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
  referenceLng: 'en',
  version: 'latest',
};

/**
 * Formats the productType based on the JSON definition in common namespace
 * Will return the given value if the productType is not translated
 *
 * @param productType
 */
const formatProductType = (productType) => {
  const productTypesJSON = i18next.t('common:productTypes');

  const productTypesObject = productTypesJSON !== 'productTypes' ? JSON.parse(productTypesJSON) : {};

  if (productTypesObject[productType]) {
    return productTypesObject[productType];
  }

  return productType;
};

const i18nOptions = {
  fallbackLng: {
    default: ['en-EU', 'en', 'nl-NL', 'nl-BE'],
    'nl-BE': ['nl-NL'],
    'en-NL': ['en-EU'],
  },
  appendNamespaceToCIMode: true,
  ns: [
    'common',
    'web-app',
    'links',
    'app_manageSubscription',
    'app_alerts',
    'app_screens',
    'app_menu',
    'shop',
    'photo_sets',
    'products',
  ],
  defaultNS: 'web-app',
  backend: {
    backends: [
      LocalStorageBackend, // primary
      LocizeBackend, // fallback
    ],
    backendOptions: [localStorageBackendOptions, locizeBackendOptions],
  },
  interpolation: {
    escapeValue: false,
    format: (rawValue, formatType, lng) => {
      if (formatType === 'formatDate') {
        return getLocaleFormattedDate(rawValue, lng);
      }

      if (formatType === 'formatMonth') {
        return getLocaleLongMonthFromTimestamp(rawValue, lng);
      }

      if (formatType.startsWith(NUMERAL_PREFIX)) {
        const formatString = removePrefix(formatType, NUMERAL_PREFIX);
        const value = Number(rawValue);

        return formatCurrency(value, formatString);
      }

      if (formatType === 'formatProductType') {
        const value = `${rawValue}`;

        return formatProductType(value);
      }

      return rawValue;
    },
  },
};

const localStoragePrefix = '@k-webApp';

export const loadTranslationsAsync = (language = 'en', useCache = true) =>
  new Promise((resolve, reject) => {
    const cachedLanguage = localStorage.getItem(`${localStoragePrefix}-languageI18Next`);
    if (!useCache) {
      global.language = language;
    }

    if (cachedLanguage && !global.language) {
      global.language = cachedLanguage;
    }

    const options = {
      ...i18nOptions,
      // Initially load uiLanguage or pathLanguage
      lng: language || global.uiLanguage || global.language,
    };

    return (
      i18next
        .use(Backend)
        // .use(reactI18nextModule)
        .use(initReactI18next)
        .init(options, (error) => {
          if (error) {
            reject(error);
          }

          resolve();
        })
    );
  });

export const changeLanguage = async (language, persistent = true) => {
  if (global.language !== language) {
    global.language = language;
  }

  if (i18next && i18next.language !== language) {
    await i18next.changeLanguage(language);
  }
  if (persistent) {
    if (global.language) {
      localStorage.setItem(`${localStoragePrefix}-languageI18Next`, global.language);
    }
  }
};

export const allowedLanguageAndCountryCodes = ['nl-NL', 'nl-BE', 'en-GB', 'fr-FR', 'en-EU', 'es-ES', 'de-DE'];

export const resolveLanguageAndCountry = (lang = 'nl-NL') => {
  const pathSegments = window.location.pathname.split('/');
  const hasPrefix = pathSegments[1] === '_';
  const pathLanguage = hasPrefix ? pathSegments[2] : pathSegments[1];

  if (allowedLanguageAndCountryCodes.includes(pathLanguage)) {
    return pathLanguage;
  }
  if (allowedLanguageAndCountryCodes.includes(lang)) {
    return lang;
  }
  return 'en-EU';
};
export const resolveLanguageAndCountryArray = (langAndCountryCode = 'nl-NL') => {
  if (allowedLanguageAndCountryCodes.includes(langAndCountryCode)) {
    const [lang, country] = langAndCountryCode.split('-');
    if (!lang || !country) {
      return [lang, country];
    }
  }
  return ['en', 'EU'];
};

export default i18next;
