import { fabric } from 'fabric';
import { TemplateObject } from '../BitmapEditor.types';

const buttons: HTMLButtonElement[] = [];

export const drawImage = async (canvas: fabric.Canvas, object: TemplateObject, selectImageHandler: any, sideContainer: string) => {
  if (object.src?.includes('{{{')) {
    drawPlaceholder(canvas, object, selectImageHandler, sideContainer);
  } else {
    drawImageElement(canvas, object, selectImageHandler);
  }
};

export const drawImageElement = (canvas: fabric.Canvas, object: any, selectImageHandler: any): void => {
  fabric.Image.fromURL(
    object.src,
    (img: any) => {
      img.set({
        id: object.id,
        left: object.x,
        top: object.y,
        scaleX: object.width / img.width,
        scaleY: object.height / img.height,
        hasControls: false,
        hasBorders: true,
        selectable: true,
        lockMovementX: true,
        lockMovementY: true,
        strokeWidth: 0,
        hoverCursor: 'pointer',
      });
      canvas.add(img);
      img.on('mouseover', () => {
        img.set('stroke', '#3355ff');
        canvas.renderAll();
      });
      img.on('mouseout', () => {
        img.set('stroke', 'transparent');
        canvas.renderAll();
      });
    },
    { crossOrigin: 'anonymous' }
  );
};

const drawPlaceholder = (canvas: fabric.Canvas, object: TemplateObject, selectImageHandler: any, sideContainer: string) => {
  const placeHolder = createPlaceholder(canvas, object);
  const fileInput = createFileInput(object, selectImageHandler);
  const button = createButton(canvas, object, selectImageHandler, sideContainer);

  fabric.loadSVGFromURL('/img/photo-editor/placeholder.svg', (objects, options) => {
    const svg = fabric.util.groupSVGElements(objects, options);
    const objectWidth = object.width ?? 100;
    const objectHeight = object.height ?? 100;

    const svgWidth = 100;
    const svgHeight = 100;

    const scale = Math.min(objectWidth / svgWidth, objectHeight / svgHeight);

    svg.set({
      top: object.y + objectHeight / 2 - (svgHeight * scale) / 10,
      left: object.x + objectWidth / 2 - (svgWidth * scale) / 10,
      scaleX: scale,
      scaleY: scale,
      evented: false,
      originX: 'left',
      originY: 'top',
    });
    canvas.add(svg);
  });

  placeHolder.on('mouseover', () => {
    placeHolder.set('strokeWidth', 10);
    canvas.renderAll();
  });
  placeHolder.on('mouseout', () => {
    placeHolder.set('strokeWidth', 0);
    canvas.renderAll();
  });
  window.addEventListener('resize', () => positionButton(placeHolder, button));

  // Position the button on the placeholder
  positionButton(placeHolder, button);
};

const createPlaceholder = (canvas: fabric.Canvas, object: TemplateObject) => {
  const placeHolder = new fabric.Rect({
    left: object.x,
    top: object.y,
    width: object.width,
    height: object.height,
    fill: '#FCF4ED',
    selectable: true,
    hasControls: false,
    lockMovementX: true,
    lockMovementY: true,
    borderScaleFactor: 3,
    stroke: '#3355ff',
    strokeWidth: 0,
    hoverCursor: 'pointer',
  });
  canvas.add(placeHolder);
  return placeHolder;
};

const createButton = (canvas: fabric.Canvas, object: TemplateObject, selectImageHandler: any, sideContainer: string) => {
  const button = document.createElement('button');
  button.innerHTML = 'klikkie ❤️';
  button.className = 'select-image-btn';
  button.style.position = 'absolute';
  button.style.zIndex = '2';
  button.style.backgroundColor = 'RGBA(0,0,0,0)';
  button.style.borderColor = 'RGBA(0,0,0,0)';
  button.style.color = 'RGBA(0,0,0,0)';
  button.style.cursor = 'pointer';

  const fullFrameDiv = document.querySelector(sideContainer);
  if (fullFrameDiv) {
    fullFrameDiv.appendChild(button);
    buttons.push(button); // Store reference to the button
  } else {
    console.error('Div with class "fullFrame" not found');
  }

  button.addEventListener('click', () => {
    const fileInput = createFileInput(object, selectImageHandler);
    fileInput.click();
  });

  button.addEventListener('mouseover', () => {
    button.style.border = '2px solid #18b2bb';
  });

  button.addEventListener('mouseout', () => {
    button.style.border = 'none';
  });

  return button;
};

const positionButton = (placeHolder: fabric.Object, button: HTMLButtonElement) => {
  const boundingRect = placeHolder.getBoundingRect();

  button.style.width = `${boundingRect.width}px`;
  button.style.height = `${boundingRect.height}px`;

  button.style.left = `${boundingRect.left}px`;
  button.style.top = `${boundingRect.top}px`;
};

const createFileInput = (object: TemplateObject, selectImageHandler: any) => {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'image/*';
  fileInput.style.display = 'none';
  document.body.appendChild(fileInput);

  fileInput.addEventListener('change', async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        selectImageHandler(e.target.result, object);
      };
      if (file.type.match('image.*')) {
        reader.readAsDataURL(file);
      } else {
        console.error('File type not supported');
      }
    }
  });

  return fileInput;
};

// Function to remove all existing buttons
const clearButtons = () => {
  buttons.forEach((button) => button.remove());
  buttons.length = 0; // Clear the buttons array
};

// Function to handle collage change
export const handleCollageChange = (
  canvas: fabric.Canvas,
  newTemplateObjects: TemplateObject[],
  selectImageHandler: any
) => {
  clearButtons();
  canvas.clear();
  // newTemplateObjects.forEach(object => drawImage(canvas, object, selectImageHandler));
};
