import React, { useEffect, useMemo, useState } from 'react';
import ProductsContainer from './Products/ProductsContainer';
import { shopProductsAndCategorySelectorUpdated } from './helpers';
import './Shop.scss';
import productsActions from '../../actions/productsActions';
import { sortProductObjects } from '../../utils/Utils';
import { localStoragePrefix } from './types';
import { useTranslation } from 'react-i18next';

const Shop = () => {
  const { t } = useTranslation();
  const [shopData, setShopData] = useState<any>({});
  const [products, setProducts] = useState<any>({});
  const productSplit = ['250', '251', '200'];
  const isLoading = useMemo(() => Object.entries(shopData).length === 0, [shopData]);

  useEffect(() => {
    // Get products from API, then update state / localStorage

    productsActions.getFormattedProducts().then((newProducts) => {
      setProducts((oldProducts: any) => {
        if (JSON.stringify(oldProducts) === JSON.stringify(newProducts)) {
          return sortProductObjects(oldProducts, productSplit);
        }
        return sortProductObjects(newProducts, productSplit);
      });
      // Save products to cache after fetching
      const productsToCache = Object.keys(newProducts);
      localStorage.setItem(`${localStoragePrefix}-products`, JSON.stringify(productsToCache));
      Object.entries(newProducts).forEach(([productId, product]) => {
        localStorage.setItem(`${localStoragePrefix}-${productId}`, JSON.stringify(product));
      });
    });
  }, [global.language]);

  const loadShopData = async () => {
    const categoryOrder = ['album', 'pictures', 'wall', 'coupon', 'subscription', 'product'];
    const data = await shopProductsAndCategorySelectorUpdated([], categoryOrder);
    setShopData(data);
  };

  useEffect(() => {
    loadShopData();
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>{t('common:loading')}</p>
      </div>
    );
  }

  return (
    <div className="shop-container">
      <ProductsContainer shopData={shopData} />
    </div>
  );
};

export default Shop;
