import { auth, googleProvider, appleProvider } from '../services/firebase';

let unsubscribe = () => null;

export const authStatusListener = (f) => {
  unsubscribe = auth.onAuthStateChanged(f);
};

export const removeAuthListener = () => {
  unsubscribe();
};

export const login = (email, password) => auth.signInWithEmailAndPassword(email, password);
export const socialSignIn = (provider) => {
  var provider;
  switch (provider) {
    case 'google':
      provider = googleProvider;
      break;
    case 'apple':
      provider = appleProvider;
      break;
    default:
      break;
  }
  return auth
    .signInWithPopup(provider)
    .then((result) => {
      var credential = result.credential;

      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      // IdP data available in result.additionalUserInfo.profile.
      // ...
      return result;
    })
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
};

export const logout = () => {
  /**
   * CleanUp all the keys that were added by local storage cache
   */

  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);

    if (key.startsWith('@k-webApp')) {
      localStorage.removeItem(key);
    }
  }

  return auth.signOut();
};

export const resetPassword = (email) => auth.sendPasswordResetEmail(email);

export const getUserBearer = () => {
  if (!auth || !auth.currentUser) {
    return null;
  }

  return auth.currentUser.getIdToken();
};

export const getUserId = () => {
  if (!auth || !auth.currentUser) {
    return null;
  }

  return auth.currentUser.uid;
};
