import { apiBaseUrl } from '../config';
import fetch from '../services/fetch';
import { getUserBearer, getUserId } from './authActions';

export default {
  getUser(userId) {
    return getUserBearer()
      .then((token) => {
        const requestBody = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        return fetch(`${apiBaseUrl}/users/${userId}`, requestBody);
      })
      .catch(() => null);
  },
  getUser() {
    const userId = getUserId();
    if (!userId) {
      return null;
    }
    return getUserBearer()
      .then((token) => {
        const requestBody = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        return fetch(`${apiBaseUrl}/users/${userId}`, requestBody);
      })
      .catch(() => null);
  },
  setUser(update) {
    return getUserBearer()
      .then(async (token) => {
        const requestBody = {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(update),
        };
        const userId = await getUserId();
        return fetch(`${apiBaseUrl}/users/${userId}/profile`, requestBody);
      })
      .catch(() => null);
  },
  deleteUser(userId) {
    return getUserBearer()
      .then(async (token) => {
        const requestBody = {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const userId = await getUserId();
        return fetch(`${apiBaseUrl}/auth/${userId}`, requestBody);
      })
      .catch(() => null);
  },
  getProfileLanguage(userId) {
    return this.getUser(userId).then((user) => {
      if (!user) {
        return global.language || 'nl-NL';
      }

      const { languageCode, countryCode } = user.profile;

      const KLIKKIE_COUNTRIES = ['NL', 'BE', 'GB', 'FR', 'ES', 'DE'];

      if (KLIKKIE_COUNTRIES.includes(countryCode) === false) {
        return 'en-EU';
      }

      // if (languageCode === 'en') {
      //   return 'en-EU';
      // }

      return `${languageCode}-${countryCode}`;
    });
  },

  setProfileLanguage(languageCode) {
    return this.setUser({ languageCode });
  },
  updateEmail(update) {
    return getUserBearer()
      .then(async (token) => {
        const requestBody = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(update),
        };
        const userId = await getUserId();
        return fetch(`${apiBaseUrl}/users/${userId}/change-email`, requestBody);
      })
      .catch(() => null);
  },
  updateAuth(update) {
    return getUserBearer()
      .then(async (token) => {
        const requestBody = {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(update),
        };
        const userId = await getUserId();
        return fetch(`${apiBaseUrl}/auth/${userId}`, requestBody);
      })
      .catch(() => null);
  },
  getCountry() {
    const requestBody = {
      method: 'GET',
    };
    return fetch('https://europe-west1-klikkie-prod.cloudfunctions.net/geoIpTS-getCountryFromIp', requestBody)
      .then((response) => response)
      .catch(() => null);
  },
  getUserId() {
    return getUserId();
  },
};
