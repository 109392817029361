import { Canvas } from 'fabric/fabric-impl';
import { TemplateObject } from '../BitmapEditor.types';
import { drawImage } from './BitmapEditor.ImageObject';
import { drawQr } from './BitmapEditor.QrObject';
import { drawText } from './BitmapEditor.TextObject';
import { drawRectangle } from './BitmapEditor.RectangleObject';
import { v4 as uuidv4 } from 'uuid';
import { drawKlikkieImageElement } from './BitmapEditor.KlikkieImageObject';
import { drawTextArea } from './BitmapEditor.TextAreaObject';
import { drawDividerLine } from './BitmapEditor.dividerObject';

export const drawObject = async (
  canvas: Canvas,
  object: TemplateObject,
  selectImageHandler: any,
  sideContainer: any
) => {
  if (!object.id) {
    object.id = uuidv4();
  }

  switch (object.type) {
    case 'qr':
      return drawQr(canvas, object);
    case 'textarea':
      const textareaObject = drawTextArea(canvas, object);
      return textareaObject;
    case 'text':
      const textObject = drawText(canvas, object);
      return textObject;
    case 'imageFromStorage':
      const imageObject = await drawImage(canvas, object, selectImageHandler, sideContainer);
      return imageObject;
    case 'klikkieLogo':
      const klikkieObject = await drawKlikkieImageElement(canvas, object);
      return klikkieObject;
    case 'divider':
      const dividerObject = drawDividerLine(canvas, object);
      return dividerObject;
    case 'rectangle':
      const rectangleObject = drawRectangle(canvas, object);
      return rectangleObject;
    default:
      throw new Error(`Unknown object type: ${object.type}`);
  }
};
