import React, { useState } from 'react';
import './Modal.scss';

type ModalProps = {
  showModal: boolean;
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
  showLoadingOnSave: boolean;
};

const Modal: React.FC<ModalProps> = ({
  showModal,
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  showLoadingOnSave,
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    if (showLoadingOnSave) {
      setLoading(true);
    }
    onConfirm();
  };

  if (!showModal) return null;

  return (
    <>
      <div className="modalOverlay" />
      <div className="modal-alert">
        <div className="modalContent">
          <h1>{title}</h1>
          <p>{message}</p>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <button onClick={onCancel} className="button btnCancel" type="button">
              {cancelText}
            </button>
            <button onClick={handleConfirm} className="button" type="button">
              {loading && <span className="loading-image"></span>}
              {!loading && confirmText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
