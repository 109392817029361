import React, { useState } from 'react';
import classNames from 'classnames';
import { FrameColors, MenuTypes } from './EditorMenu';
import { trackingClick } from '../../../actions/trackingActions';
import { useTranslation } from 'react-i18next';

export interface FrameColorMenuProps {
  productColors: FrameColors[];
  selectedFrame: FrameColors;
  setSelectedFrame: (frame: FrameColors) => void;
  setSelectedMenu: (menu: MenuTypes) => void;
}

export default function FrameColorMenu(
  { 
    productColors, 
    selectedFrame, 
    setSelectedFrame, 
    setSelectedMenu 
  }: FrameColorMenuProps
): JSX.Element {
  const [selectedColor, setSelectedColor] = useState<FrameColors>(FrameColors.BLACK);
  const { t } = useTranslation();
  return (
    <>
      {productColors &&
        productColors.map((color) => {
          const lowerCaseColor: FrameColors = color.toLowerCase().replace(' ', '') as unknown as FrameColors;
          return (
            <div
              key={color}
              className="frameColorMenu"
              onClick={() => {
                trackingClick('bitmapEditor', `select_${lowerCaseColor}_frame`);
                setSelectedFrame(color);
                setSelectedColor(lowerCaseColor);
                setSelectedMenu(MenuTypes.DEFAULT);
              }}
              onMouseOver={() => {
                setSelectedFrame(color);
              }}
              onMouseOut={() => {
                setSelectedFrame(selectedColor);
              }}
            >
              <div
                style={{ backgroundColor: color }}
                className={classNames('frameColor', `${lowerCaseColor}Frame`, {
                  frameSelected: selectedFrame === color,
                })}
              />
              <p>{t(`BitmapEditor.menu.${lowerCaseColor}`)}</p>
            </div>
          );
        })}
    </>
  )
};
